/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneConsent as ResolvedStandaloneConsent } from 'base/components/standalone/Consent';

export const Consent = ResolvedStandaloneConsent;

export type ConsentProps = PropsFromComponent<typeof Consent>;

export const ConsentWith = (extras: DynamicStandaloneExtras): typeof Consent => {
    return function Consent(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Consent');
        return <ResolvedStandaloneConsent {...mergeProps({ options: { theme } }, props)} />;
    }
}