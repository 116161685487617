/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneImage as ResolvedStandaloneImage } from 'base/components/standalone/Image';

export const Image = ResolvedStandaloneImage;

export type ImageProps = PropsFromComponent<typeof Image>;

export const ImageWith = (extras: DynamicStandaloneExtras): typeof Image => {
    return function Image(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Image');
        return <ResolvedStandaloneImage {...mergeProps({ options: { theme } }, props)} />;
    }
}