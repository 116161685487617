/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneTracking as ResolvedStandaloneTracking } from 'base/components/standalone/Tracking';

export const Tracking = ResolvedStandaloneTracking;

export type TrackingProps = PropsFromComponent<typeof Tracking>;

export const TrackingWith = (extras: DynamicStandaloneExtras): typeof Tracking => {
    return function Tracking(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Tracking');
        return <ResolvedStandaloneTracking {...mergeProps({ options: { theme } }, props)} />;
    }
}