/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneAdTemplate as ResolvedStandaloneAdTemplate } from 'base/components/standalone/AdTemplate';

export const AdTemplate = ResolvedStandaloneAdTemplate;

export type AdTemplateProps = PropsFromComponent<typeof AdTemplate>;

export const AdTemplateWith = (extras: DynamicStandaloneExtras): typeof AdTemplate => {
    return function AdTemplate(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'AdTemplate');
        return <ResolvedStandaloneAdTemplate {...mergeProps({ options: { theme } }, props)} />;
    }
}