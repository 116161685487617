import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { jsonify } from 'lib/utils';
import getConfig from 'modules/config';
import Script from 'next/script';
import Gtm from './Gtm';
interface AnalyticsConfig {
  ga: {
    measurementId: string;
    propertyId: string;
  };
  sifo: {
    id: string;
  };
  tealium: {
    account: string;
    profile: string;
    environment: string;
  };
  avo: {
    url: string;
  };
  funnelRelay: {
    url: string;
  };
  tags: string[];
  jwplayer: boolean;
}

const publicToolsPath = '/common/js/tools';
const analyticsConfig = getConfig('analytics');
const ctsVersion = analyticsConfig.ctsVersion || 'v1.12.16';
const ctsScriptUrl = `//cdn.aller.se/cts/${ctsVersion}/se-ocelot/bundle.js`;

const ctsConfig = Object.keys(analyticsConfig).reduce((acc, key) => {
  const val = analyticsConfig[key];
  if (key === 'google') {
    acc.ga = {
      measurementId: val.measurement_id,
      propertyId: val.tracking_id,
    };
  } else {
    acc[key as keyof AnalyticsConfig] = val;
  }
  return acc;
}, {} as AnalyticsConfig);

export interface StandaloneTrackingProps extends StandaloneComponentProps {
  trackingData?: Record<string, string>;
  allerGaData?: Record<string, string>;
}

export const StandaloneTracking: StandaloneComponent<StandaloneTrackingProps> = ({ trackingData, allerGaData }) => {
  return (
    <>
      <Script id="first-party-cookie-handling" src={`${publicToolsPath}/fptools.js`} />

      {analyticsConfig?.google?.gtm && <Gtm gtmId={analyticsConfig?.google?.gtm} />}

      <Script
        id="cts-tracking"
        data-script-url={ctsScriptUrl}
        data-allerga={jsonify(allerGaData)}
        data-metadata={jsonify(trackingData)}
        data-config={jsonify(ctsConfig)}
        src={`${publicToolsPath}/cts.js`}
      />
    </>
  );
};
