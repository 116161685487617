import { useAppState } from '@/hooks/useAppState';
import { useClassName } from '@/styles/Image';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { GenericComponentFactory } from 'base/components/GenericComponent';
import { gridSystemConfig } from 'base/configs/gridSystem';
import { labradorImageLoader } from 'lib/image';
import { getBackendImageServer, getImageServer } from 'lib/utils';
import { cn } from 'lib/utils/cn';
import getUrl from 'lib/utils/getUrl';
import { mergeProps } from 'lib/utils/merge';
import NextImage from 'next/image';
import { useCallback, useState } from 'react';
import { isEmpty, isString, isUndefined } from 'typesafe-utils';

const blurDataURLDesktop = getUrl(
  `data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1NjgiIGhlaWdodD0iMjg5IiBmaWxsPSJub25lIiB4bWxuczp2PSJodHRwczovL3ZlY3RhLmlvL25hbm8iPjxwYXRoIGZpbGw9IiNlNmU2ZTYiIGQ9Ik0wIDBoNTY4djI4OUgweiIvPjxnIGZpbGw9IiM4ZDhkOGQiPjxjaXJjbGUgY3g9IjI1OSIgY3k9IjE0NSIgcj0iOSIvPjxjaXJjbGUgY3g9IjI4NC4xOTUiIGN5PSIxNDUiIHI9IjkiLz48Y2lyY2xlIGN4PSIzMDkuNDAyIiBjeT0iMTQ1IiByPSI5Ii8+PC9nPjwvc3ZnPg==`
) as URL;

const blurDataURLMobile = getUrl(
  `data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MDAiIGhlaWdodD0iMjA3IiBmaWxsPSJub25lIiB4bWxuczp2PSJodHRwczovL3ZlY3RhLmlvL25hbm8iPjxwYXRoIGZpbGw9IiNlNmU2ZTYiIGQ9Ik0wIDBoNDAwdjIwN0gweiIvPjxnIGZpbGw9IiM4ZDhkOGQiPjxjaXJjbGUgY3g9IjE3NSIgY3k9IjEwNCIgcj0iOSIvPjxjaXJjbGUgY3g9IjIwMC4xOTUiIGN5PSIxMDQiIHI9IjkiLz48Y2lyY2xlIGN4PSIyMjUuNDAyIiBjeT0iMTA0IiByPSI5Ii8+PC9nPjwvc3ZnPg==`
) as URL;

const getImageUrl = (src: ImageProps['src'], fallback: URL): URL => {
  if (isUndefined(src) || !isString(src) || isEmpty(src) || src === '/') {
    return fallback;
  }

  return getUrl(src, getImageServer()) ?? fallback;
};

export interface ImageProps extends ComponentProps<typeof NextImage> {
  maxWidth?: number;
  size?: ClassNameProp<'default'>;
  variant?: ClassNameProp<'default'>;
}

export const ImageComponent: Component<Partial<ImageProps>> = ({
  alt = '',
  children,
  src = '',
  width,
  maxWidth,
  height,
  ...props
}) => {
  const [{ device }] = useAppState();
  const [isError, setError] = useState(false);

  const blurDataURL = device === 'desktop' ? blurDataURLDesktop : blurDataURLMobile;

  const { searchParams } = isString(src) ? getImageUrl(src, blurDataURL) : ({} as any);

  const dimensions = {
    width: Number(width || searchParams?.get('width')) || undefined,
    height: Number(height || searchParams?.get('height')) || undefined,
    scaled: {
      width: 0,
      height: 0,
    },
  };

  const scale = dimensions.width && maxWidth ? Math.min(maxWidth / dimensions.width, 1) : 1;

  if (scale < 1) {
    // @ts-expect-error
    dimensions.scaled.width = Math.round(dimensions.width * scale);
    // @ts-expect-error
    dimensions.scaled.height = Math.round(dimensions.height * scale);

    if (searchParams?.has('width')) {
      searchParams.set('width', String(dimensions.scaled.width));
    }

    if (searchParams?.has('height')) {
      searchParams.set('height', String(dimensions.scaled.height));
    }
  }

  const fill = props.fill || !dimensions.width || !dimensions.height;

  const maxContentWidth = maxWidth ? `${maxWidth}px` : gridSystemConfig.screens.lg.maxContentWidth;
  const sizes = `(max-width: ${maxContentWidth}) 100vw, ${maxContentWidth}`;

  const imageClassName = useClassName('', props);
  const pictureClassName = cn(imageClassName, props.className, fill && 'relative');

  const dimensionsProps = fill
    ? null
    : {
        width: dimensions.scaled.width || dimensions.width,
        height: dimensions.scaled.height || dimensions.height,
      };

  if (isString(src) && src.startsWith(getBackendImageServer())) props.referrerPolicy = 'no-referrer';

  const onError = useCallback(() => setError(true), []);

  return (
    <picture data-fill={fill ? '' : undefined} className={pictureClassName} style={props.style}>
      <NextImage
        alt={alt}
        src={isError ? blurDataURL.href : src}
        {...mergeProps(
          {
            className: imageClassName,
            blurDataURL: blurDataURL.href,
            placeholder: 'blur',
            loader: labradorImageLoader,
            fill,
            sizes,
            onError,
            ...dimensionsProps,
          },
          props
        )}
      />
    </picture>
  );
};

const GenericComponent = GenericComponentFactory({ useClassName });
const Caption = GenericComponent({ as: 'figcaption', styles: { key: 'caption' } });
const Text = GenericComponent({ as: 'span', styles: { key: 'caption_text' } });
const Byline = GenericComponent({ as: 'span', styles: { key: 'caption_byline' } });
const Group = GenericComponent({ as: 'figure', styles: { key: 'group' } });

export const Image = Object.assign(ImageComponent, {
  Caption: Object.assign(Caption, {
    Text,
    Byline,
  }),
  Group,
});
