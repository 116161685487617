/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneAdunit as ResolvedStandaloneAdunit } from 'base/components/standalone/Adunit';

export const Adunit = ResolvedStandaloneAdunit;

export type AdunitProps = PropsFromComponent<typeof Adunit>;

export const AdunitWith = (extras: DynamicStandaloneExtras): typeof Adunit => {
    return function Adunit(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Adunit');
        return <ResolvedStandaloneAdunit {...mergeProps({ options: { theme } }, props)} />;
    }
}