import { OptionalLink, OptionalLinkProps } from '@/components/OptionalLink';
import { useStable } from '@/hooks/useStable';
import { ClassNameProvider } from '@/styles/Image';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import type { ReactNode } from 'react';
import { Image, ImageProps } from './Image';

export interface StandaloneImageProps extends StandaloneComponentProps<typeof Image> {
  alt?: ImageProps['alt'];
  byline?: ReactNode;
  caption?: ReactNode;
  fallback?: ImageProps['src'];
  link?: OptionalLinkProps;
  src?: ImageProps['src'];
}

export const StandaloneImage: StandaloneComponent<StandaloneImageProps> = ({
  alt = '',
  byline,
  caption,
  link,
  src = '',
  options,
  ...props
}) => {
  const { $caption: captionProps, $group, ...$base } = { ...options };
  const { $text: $captionText, $byline: $captionByline, ...$caption } = { ...captionProps };

  const stableProviderProps = useStable({
    value: $base,
    options: {
      fallbacks: {
        colors: 'variant',
        size: 'variant',
      },
    } as const,
  });

  return (
    <ClassNameProvider {...stableProviderProps}>
      <Image.Group {...$group}>
        <OptionalLink content={<Image {...{ src, alt }} {...$base} {...props} />} {...link} />

        {(caption || byline) && (
          <Image.Caption {...$caption}>
            {caption && <Image.Caption.Text {...$captionText}>{caption}</Image.Caption.Text>}
            {byline && <Image.Caption.Byline {...$captionByline}>{byline}</Image.Caption.Byline>}
          </Image.Caption>
        )}
      </Image.Group>
    </ClassNameProvider>
  );
};
